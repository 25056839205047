<template>
    <Head :title="$t('franchise.information_request')" />

    <div class="relative bg-white dark:bg-gray-900">
        <div class="lg:fixed lg:inset-0 lg:left-1/2">
            <img
                class="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full"
                src="../../images/franchise2.jpg"
                alt="Onigiri Shop"
            />
        </div>
        <div class="pb-24 sm:pb-32 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-16">
            <div class="px-6 lg:px-8">
                <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                    <div class="sm:mb-10">
                        <img class="h-36" src="../../images/logo.svg" alt="Onigiri Shop" />
                    </div>

                    <h2 class="text-3xl font-bold tracking-tight text-gray-900">
                        {{ $t('franchise.information_request') }}
                    </h2>
                    <p class="mt-2 text-lg leading-8 text-gray-600">
                        {{ $t('franchise.information_request_description') }}
                    </p>
                    <form @submit.prevent="submit" class="mt-16">
                        <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                            <FormInput
                                v-model="form.name"
                                :error="form.errors.name"
                                :required="true"
                                :label="$t('franchise.form.name')"
                            />

                            <FormInput
                                type="date"
                                v-model="form.date_of_birth"
                                :error="form.errors.date_of_birth"
                                :required="true"
                                :label="$t('franchise.form.date_of_birth')"
                            />

                            <FormInput
                                v-model="form.phone_number"
                                :error="form.errors.phone_number"
                                :required="true"
                                :label="$t('franchise.form.phone_number')"
                            />

                            <FormInput
                                type="email"
                                v-model="form.email"
                                :error="form.errors.email"
                                :required="true"
                                :label="$t('franchise.form.email')"
                            />

                            <div class="sm:col-span-2">
                                <FormInput
                                    v-model="form.address"
                                    :error="form.errors.address"
                                    :required="true"
                                    :label="$t('franchise.form.address')"
                                />
                            </div>

                            <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:col-span-2 sm:grid-cols-3">
                                <FormInput
                                    v-model="form.city"
                                    :error="form.errors.city"
                                    :required="true"
                                    :label="$t('franchise.form.city')"
                                />
                                <FormInput
                                    v-model="form.province"
                                    :error="form.errors.province"
                                    :required="true"
                                    :label="$t('franchise.form.province')"
                                />
                                <FormInput
                                    v-model="form.postal_code"
                                    :error="form.errors.postal_code"
                                    :required="true"
                                    :label="$t('franchise.form.postal_code')"
                                />
                            </div>

                            <div class="sm:col-span-2">
                                <FormTextarea
                                    v-model="form.education"
                                    :error="form.errors.education"
                                    :required="true"
                                    :label="$t('franchise.form.education')"
                                    :rows="5"
                                />
                            </div>

                            <div class="sm:col-span-2">
                                <FormTextarea
                                    v-model="form.work_experience"
                                    :error="form.errors.work_experience"
                                    :required="true"
                                    :label="$t('franchise.form.work_experience')"
                                    :rows="5"
                                />
                            </div>

                            <FormInput
                                v-model="form.current_income"
                                :error="form.errors.current_income"
                                :required="true"
                                :label="$t('franchise.form.current_income')"
                            />

                            <FormInput
                                v-model="form.other_income"
                                :error="form.errors.other_income"
                                :label="$t('franchise.form.other_income')"
                            />

                            <div class="sm:col-span-2">
                                <FormInput
                                    v-model="form.specify_other_income"
                                    :error="form.errors.specify_other_income"
                                    :label="$t('franchise.form.specify_other_income')"
                                />
                            </div>

                            <div class="sm:col-span-2">
                                <FormInput
                                    v-model="form.bank"
                                    :error="form.errors.bank"
                                    :required="true"
                                    :label="$t('franchise.form.bank')"
                                />
                            </div>

                            <fieldset class="sm:col-span-2">
                                <legend class="form-label required">
                                    {{ $t('franchise.form.criminal_record') }}
                                </legend>
                                <div class="grid grid-cols-2 gap-x-4 text-sm leading-6 text-gray-600">
                                    <FormRadio
                                        v-model="form.criminal_record"
                                        value="yes"
                                        :label="$t('franchise.form.yes')"
                                    />
                                    <FormRadio
                                        v-model="form.criminal_record"
                                        value="no"
                                        :label="$t('franchise.form.no')"
                                    />
                                </div>
                            </fieldset>

                            <fieldset class="sm:col-span-2">
                                <legend class="form-label required">
                                    {{ $t('franchise.form.franchise_will_be_the_only_source_of_revenue') }}
                                </legend>
                                <div class="grid grid-cols-2 gap-x-4 text-sm leading-6 text-gray-600">
                                    <FormRadio
                                        v-model="form.franchise_will_be_the_only_source_of_revenue"
                                        value="yes"
                                        :label="$t('franchise.form.yes')"
                                    />
                                    <FormRadio
                                        v-model="form.franchise_will_be_the_only_source_of_revenue"
                                        value="no"
                                        :label="$t('franchise.form.no')"
                                    />
                                </div>
                            </fieldset>

                            <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:col-span-2 sm:grid-cols-3">
                                <FormInput
                                    v-model="form.total_assets"
                                    :error="form.errors.total_assets"
                                    :required="true"
                                    :label="$t('franchise.form.total_assets')"
                                />
                                <FormInput
                                    v-model="form.total_debts"
                                    :error="form.errors.total_debts"
                                    :required="true"
                                    :label="$t('franchise.form.total_debts')"
                                />
                                <FormInput
                                    v-model="form.net_worth"
                                    :error="form.errors.net_worth"
                                    :required="true"
                                    :label="$t('franchise.form.net_worth')"
                                />
                            </div>

                            <div class="sm:col-span-2">
                                <FormInput
                                    v-model="form.amount_available_for_investment"
                                    :error="form.errors.amount_available_for_investment"
                                    :required="true"
                                    :label="$t('franchise.form.amount_available_for_investment')"
                                />
                            </div>

                            <div class="sm:col-span-2">
                                <FormInput
                                    v-model="form.financing_source"
                                    :error="form.errors.financing_source"
                                    :required="true"
                                    :label="$t('franchise.form.financing_source')"
                                />
                            </div>

                            <FormInput
                                v-model="form.credit_rating"
                                :error="form.errors.credit_rating"
                                :required="true"
                                :label="$t('franchise.form.credit_rating')"
                            />

                            <div class="sm:col-span-2">
                                <FormInput
                                    v-model="form.opening_date"
                                    :error="form.errors.opening_date"
                                    :required="true"
                                    :label="$t('franchise.form.opening_date')"
                                />
                            </div>

                            <div class="sm:col-span-2">
                                <FormInput
                                    v-model="form.location"
                                    :error="form.errors.location"
                                    :required="true"
                                    :label="$t('franchise.form.location')"
                                />
                            </div>

                            <fieldset class="sm:col-span-2">
                                <legend class="form-label required">
                                    {{ $t('franchise.form.operator') }}
                                </legend>
                                <div class="grid grid-cols-2 gap-x-4 text-sm leading-6 text-gray-600">
                                    <FormRadio v-model="form.operator" value="yes" :label="$t('franchise.form.yes')" />
                                    <FormRadio v-model="form.operator" value="no" :label="$t('franchise.form.no')" />
                                </div>
                            </fieldset>

                            <div class="sm:col-span-2">
                                <FormInput
                                    v-model="form.if_not_operator"
                                    :error="form.errors.if_not_operator"
                                    :label="$t('franchise.form.if_not_operator')"
                                />
                            </div>

                            <fieldset class="sm:col-span-2">
                                <legend class="form-label required">
                                    {{ $t('franchise.form.short_training') }}
                                </legend>
                                <div class="grid grid-cols-2 gap-x-4 text-sm leading-6 text-gray-600">
                                    <FormRadio
                                        v-model="form.short_training"
                                        value="yes"
                                        :label="$t('franchise.form.yes')"
                                    />
                                    <FormRadio
                                        v-model="form.short_training"
                                        value="no"
                                        :label="$t('franchise.form.no')"
                                    />
                                </div>
                            </fieldset>

                            <fieldset class="sm:col-span-2">
                                <legend class="form-label required">
                                    {{ $t('franchise.form.long_training') }}
                                </legend>
                                <div class="grid grid-cols-2 gap-x-4 text-sm leading-6 text-gray-600">
                                    <FormRadio
                                        v-model="form.long_training"
                                        value="yes"
                                        :label="$t('franchise.form.yes')"
                                    />
                                    <FormRadio
                                        v-model="form.long_training"
                                        value="no"
                                        :label="$t('franchise.form.no')"
                                    />
                                </div>
                            </fieldset>

                            <div class="sm:col-span-2">
                                <FormTextarea
                                    v-model="form.other_party"
                                    :error="form.errors.other_party"
                                    :required="true"
                                    :label="$t('franchise.form.other_party')"
                                />
                            </div>
                        </div>
                        <div class="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
                            <Button type="submit" :loading="form.processing" class="w-24">
                                {{ $t('actions.submit') }}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <Modal :open="openConfirmationModal" :overlayClose="false" size="sm">
        <Card class="mx-3 p-6 lg:p-10">
            <div class="mx-auto flex size-20 items-center justify-center rounded-full bg-green-100">
                <svg
                    class="size-10 text-green-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-base font-semibold leading-6 text-gray-900 lg:text-xl">
                    {{ $t('franchise.success') }}
                </h3>
                <div class="mt-2">
                    <p class="text-sm text-gray-500 lg:text-base">
                        {{ $t('franchise.success_message') }}
                    </p>
                </div>
            </div>
        </Card>
    </Modal>
</template>

<script setup>
import { ref } from 'vue';
import { Head, useForm } from '@inertiajs/vue3';
import { Card, Button, Modal } from '@/Components';
import { FormInput, FormTextarea, FormRadio } from '@/Components';

const props = defineProps({
    referrer: String,
});

const form = useForm({
    referrer: props.referrer,
    name: null,
    date_of_birth: null,
    phone_number: null,
    email: null,
    address: null,
    city: null,
    province: null,
    postal_code: null,
    education: null,
    work_experience: null,
    current_income: null,
    other_income: null,
    specify_other_income: null,
    bank: null,
    criminal_record: null,
    franchise_will_be_the_only_source_of_revenue: null,
    total_assets: null,
    total_debts: null,
    net_worth: null,
    amount_available_for_investment: null,
    financing_source: null,
    credit_rating: null,
    opening_date: null,
    location: null,
    operator: null,
    if_not_operator: null,
    short_training: null,
    long_training: null,
    other_party: null,
});

const openConfirmationModal = ref(false);

const submit = () => {
    form.post(route('franchise.store'), {
        onSuccess: () => {
            form.reset();
            openConfirmationModal.value = true;
        },
    });
};
</script>
